import React, { useState, useEffect } from "react";
import { Location } from "./Location";
import { AdditionalInfo } from "./AdditionalInfo";
import { Highlights } from "./Highlights";
import { ActivityList } from "./ActivityList";
import { AvailableDate } from "./AvailableDate";
import { AvailableDatesModal } from "./availableDatesModal";
import { ActivityModal } from "./ActivityModal";
import { SelectPreference } from "./selectPreference";
import { SelectSpec } from "./ActivitySpecModal";
import { CustomerReviews } from "./CustomerReviews";

export const ActivityDetails = ({ proData, actList }) => {
  // Pass proDataArr as a prop

  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showActivityModal, setShowActivityModal] = useState(false);
  const [showPreferenceModal, setShowPreferenceModal] = useState(false);
  const [showSpecModal, setShowSpecModal] = useState(false);
  const [selItem, setSelItem] = useState(null);

  const closeActivityModal = () => {
    setShowActivityModal(false);
  };

  const openCalendarModal = (item) => {
    setSelItem(item);
    closeActivityModal();
    setShowCalendarModal(true);
    setShowSpecModal(false);
    setShowPreferenceModal(false);
  };

  const closeCalendarModal = () => {
    setShowCalendarModal(false);
  };

  const handleOpenAvailableDatesModal = () => {
    openCalendarModal();
    hidePreferenceModal();
  };

  const closePreferenceModal = () => {
    setShowPreferenceModal(false);
  };

  const openPreferenceModal = () => {
    setShowPreferenceModal(true);
  };

  const openSpecModal = () => {
    setShowSpecModal(true);
  };
  const closeSpecModal = () => {
    setShowSpecModal(false);
  };
  const hideSpecModal = () => {
    setShowSpecModal(false);
    setShowPreferenceModal(false);
  };

  const hidePreferenceModal = () => {
    setShowPreferenceModal(false);
  };

  const handleEditDate = () => {
    hidePreferenceModal();
    openPreferenceModal();
  };

  const [specCount, setSpecCount] = useState(0);
  const [tick_type, setTick_type] = useState(0);
  const [enabledDates, setEnabledDates] = useState([]);

  useEffect(() => {
    // Set specCount to proData.specCount when proData changes
    if (proData && proData.specCount !== undefined) {
      setSpecCount(proData.specCount);
    }
    if (proData && proData.tick_type !== undefined) {
      setTick_type(proData.tick_type);
    }
    setEnabledDates(proData.arr_avail_dates);
  }, [proData]);

  const [selectedItem, setSelectedItem] = useState(null);
  const handleSelect = (item) => {
    if (specCount > 2 || tick_type == 1) {
      setSelectedItem(item); // Set the selected item in state
      openSpecModal(item, selectedDate); // Set the selected item in state
    } else {
      setSelectedItem(item); // Set the selected item in state
      openPreferenceModal(item, selectedDate); // Pass item and selectedDate to openPreferenceModal
    }
  };

  return (
    <>
      <AvailableDate
        proData={proData}
        onDateSelected={setSelectedDate}
        openCalendarModal={openCalendarModal}
        selectedDate={selectedDate}
        enabledDates={enabledDates}
      />
      <ActivityList
        proData={proData}
        showActivity={showActivityModal}
        actList={actList}
        handleSelect={handleSelect}
        openCalendarModal={openCalendarModal}
        openPreferenceModal={openPreferenceModal}
        selectedDate={selectedDate}
      />
      <Highlights proData={proData} />
      <CustomerReviews proData={proData} />
      <AdditionalInfo proData={proData} />
      <Location proData={proData} />

      {/* Modals */}
      <ActivityModal
        proData={proData} // Use proDataArr as a prop
        showActivity={showActivityModal}
        onHide={closeActivityModal}
        openAvailableDatesModal={handleOpenAvailableDatesModal}
      />

      <AvailableDatesModal
        proData={proData}
        showCalendar={showCalendarModal}
        onHide={closeCalendarModal}
        onDateSelected={setSelectedDate}
        selectedDate={selectedDate}
        enabledDates={enabledDates}
        selItem={selItem}
        handleSelect={handleSelect}
      />

      <SelectPreference
        proData={proData}
        setShowPreferenceModal={setShowPreferenceModal}
        item={selectedItem}
        selectedDate={selectedDate}
        showPreference={showPreferenceModal}
        onHide={closePreferenceModal}
        openCalendarModal={openCalendarModal}
        handleEditDate={handleEditDate}
        hidePreferenceModal={hidePreferenceModal}
      />

      <SelectSpec
        proData={proData}
        item={selectedItem}
        selectedDate={selectedDate}
        showSpec={showSpecModal}
        onHide={closeSpecModal}
        closeSpecModal={closeSpecModal}
        openCalendarModal={openCalendarModal}
        handleEditDate={handleEditDate}
        hideSpecModal={hideSpecModal}
        setSelectedItem={setSelectedItem}
        openPreferenceModal={openPreferenceModal}
        setSpecCount={setSpecCount}
        specCount={specCount}
      />
    </>
  );
};
